import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import axios from "axios";
import DonutChart from "./CommonDonutChart";
import "./App.css";
import swastik from "./swastik.svg";

const CommonElection = () => {
  const API_URL = "https://new-wapi.sandesh.com/api/v1/election";
  const [votingData, setVotingData] = useState([]);
  const [votingData1, setVotingData1] = useState([]);
  const [votingData2, setVotingData2] = useState([]);
  const [votingData3, setVotingData3] = useState([]);

  const [zone, setZone] = useState(votingData);
  const [_zone1, set_Zone1] = useState(votingData1);
  const [zone2, setZone2] = useState(votingData2);
  const [zone3, setZone3] = useState(votingData3);

  const [voting, setVoting] = useState([]);

  const [my_time, setMy_time] = useState(0);
  const [_mytime, set_Mytime] = useState(0);

  const [my_time1, setMy_time1] = useState(0);
  const [_mytime1, set_Mytime1] = useState(0);

  const [my_time2, setMy_time2] = useState(0);
  const [_mytime2, set_Mytime2] = useState(0);

  const [my_time3, setMy_time3] = useState(0);
  const [_mytime3, set_Mytime3] = useState(0);

  const [len, setLen] = useState(0);
  const [len1, setLen1] = useState(0);
  const [len2, setLen2] = useState(0);
  const [len3, setLen3] = useState(0);

  const [dynamicClasses, setDynamicClasses] = useState([]);
  const [dynamicClasses1, setDynamicClasses1] = useState([]);
  const [dynamicClasses2, setDynamicClasses2] = useState([]);
  const [dynamicClasses3, setDynamicClasses3] = useState([]);

  const [extrarow, setExtrarow] = useState(0);
  const [extrarow1, setExtrarow1] = useState(0);
  const [extrarow2, setExtrarow2] = useState(0);
  const [extrarow3, setExtrarow3] = useState(0);

  var mytimelen = 0;
  var mytimelen1 = 0;
  var mytimelen2 = 0;
  var mytimelen3 = 0;

  var start = 0;
  var j = 6;
  var start1 = 0;
  var j1 = 6;
  var start2 = 0;
  var j2 = 6;
  var start3 = 0;
  var j3 = 6;

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (j1 >= mytimelen1) {
        j1 = 0;
        start1 = -6;
        setMy_time1(0);
      }
      if (j2 >= mytimelen2) {
        j2 = 0;
        start2 = -6;
        setMy_time2(0);
      }
      if (j3 >= mytimelen3) {
        j3 = 0;
        start3 = -6;
        setMy_time3(0);
      }
      if (j >= mytimelen) {
        j = 0;
        start = -6;
        setMy_time(0);
      }
      setMy_time1((start1 += 6));
      set_Mytime1((j1 += 6));

      setMy_time2((start2 += 6));
      set_Mytime2((j2 += 6));

      setMy_time3((start3 += 6));
      set_Mytime3((j3 += 6));

      setMy_time((start += 6));
      set_Mytime((j += 6));
    }, 5000);

    return () => clearInterval(myInterval);
  }, [mytimelen, mytimelen1, mytimelen2, mytimelen3]);

  const modulo = len % 6;
  const modulo1 = len1 % 6;
  const modulo2 = len2 % 6;
  const modulo3 = len3 % 6;

  useEffect(() => {
    const addingrow = 6 - modulo;
    const addingrow1 = 6 - modulo1;
    const addingrow2 = 6 - modulo2;
    const addingrow3 = 6 - modulo3;

    setExtrarow1(addingrow1);
    setExtrarow(addingrow);
    setExtrarow2(addingrow2);
    setExtrarow3(addingrow3);
  });

  const fetchLocation = async () => {
    const data = await axios.get(API_URL);

    const _new = data.data.data?.zone1?.inner;
    const _new1 = data.data.data?.zone2?.inner;
    const _new2 = data.data.data?.zone3?.inner;
    const _new3 = data.data.data?.zone4?.inner;

    setZone(data?.data?.data?.zone1?.inner);
    setVotingData(data?.data?.data?.zone1?.inner);
    set_Zone1(data?.data?.data?.zone2?.inner);
    setVotingData1(data?.data?.data?.zone2?.inner);
    setZone2(data?.data?.data?.zone3?.inner);
    setVotingData2(data?.data?.data?.zone3?.inner);
    setZone3(data?.data?.data?.zone4?.inner);
    setVotingData3(data?.data?.data?.zone4?.inner);

    const len = data.data.data?.zone1?.inner?.length;
    const len1 = data.data.data?.zone2?.inner?.length;
    const len2 = data.data.data?.zone3?.inner?.length;
    const len3 = data.data.data?.zone4?.inner?.length;

    mytimelen = len;
    mytimelen1 = len1;
    mytimelen2 = len2;
    mytimelen3 = len3;

    setLen(len);
    setLen1(len1);
    setLen2(len2);
    setLen3(len3);

    for (let i = 0; i < extrarow; i++) {
      _new.push({});
    }
    for (let i = 0; i < extrarow1; i++) {
      _new1.push({});
    }
    for (let i = 0; i < extrarow2; i++) {
      _new2.push({});
    }
    for (let i = 0; i < extrarow3; i++) {
      _new3.push({});
    }

    setVotingData(_new);
    setVotingData1(_new1);
    setVotingData2(_new2);
    setVotingData3(_new3);

    setVoting(data.data.data);
  };

  useEffect(() => {
    zone.map((ele, index) => {
      if (votingData?.length > 0) {
        if (ele?.bjp !== votingData[index]?.bjp) {
          setDynamicClasses((current) => [...current, `bjp ${index}`]);

          setTimeout(() => {
            setDynamicClasses([]);
          }, 5000);
        }
        if (ele?.aap !== votingData[index]?.aap) {
          setDynamicClasses((current) => [...current, `aap ${index}`]);
          setTimeout(() => {
            setDynamicClasses([]);
          }, 5000);
        }
        if (ele?.cong !== votingData[index]?.cong) {
          setDynamicClasses((current) => [...current, `cong ${index}`]);
          setTimeout(() => {
            setDynamicClasses([]);
          }, 5000);
        }
        if (ele?.other !== votingData[index]?.other) {
          setDynamicClasses((current) => [...current, `other ${index}`]);
          setTimeout(() => {
            setDynamicClasses([]);
          }, 5000);
        }
      }
    });
  }, [zone]);

  useEffect(() => {
    _zone1.map((ele, index) => {
      if (votingData1?.length > 0) {
        if (ele?.bjp !== votingData1[index]?.bjp) {
          setDynamicClasses1((current) => [...current, `bjp ${index}`]);
          setTimeout(() => {
            setDynamicClasses1([]);
          }, 5000);
        }
        if (ele?.aap !== votingData1[index]?.aap) {
          setDynamicClasses1((current) => [...current, `aap ${index}`]);
          setTimeout(() => {
            setDynamicClasses1([]);
          }, 5000);
        }
        if (ele?.cong !== votingData1[index]?.cong) {
          setDynamicClasses1((current) => [...current, `cong ${index}`]);
          setTimeout(() => {
            setDynamicClasses1([]);
          }, 5000);
        }
        if (ele?.other !== votingData1[index]?.other) {
          setDynamicClasses1((current) => [...current, `other ${index}`]);
          setTimeout(() => {
            setDynamicClasses1([]);
          }, 5000);
        }
      }
    });
  }, [_zone1]);

  useEffect(() => {
    zone2.map((ele, index) => {
      if (votingData2?.length > 0) {
        if (ele?.bjp !== votingData2[index]?.bjp) {
          setDynamicClasses2((current) => [...current, `bjp ${index}`]);
          setTimeout(() => {
            setDynamicClasses2([]);
          }, 5000);
        }
        if (ele?.aap !== votingData2[index]?.aap) {
          setDynamicClasses2((current) => [...current, `aap ${index}`]);
          setTimeout(() => {
            setDynamicClasses2([]);
          }, 5000);
        }
        if (ele?.cong !== votingData2[index]?.cong) {
          setDynamicClasses2((current) => [...current, `cong ${index}`]);
          setTimeout(() => {
            setDynamicClasses2([]);
          }, 5000);
        }
        if (ele?.other !== votingData2[index]?.other) {
          setDynamicClasses2((current) => [...current, `other ${index}`]);
          setTimeout(() => {
            setDynamicClasses2([]);
          }, 5000);
        }
      }
    });
  }, [zone2]);

  useEffect(() => {
    zone3.map((ele, index) => {
      if (votingData3.length > 0) {
        if (ele?.bjp !== votingData3[index]?.bjp) {
          setDynamicClasses3((current) => [...current, `bjp ${index}`]);
          setTimeout(() => {
            setDynamicClasses3([]);
          }, 5000);
        }
        if (ele?.aap !== votingData3[index]?.aap) {
          setDynamicClasses3((current) => [...current, `aap ${index}`]);
          setTimeout(() => {
            setDynamicClasses3([]);
          }, 5000);
        }
        if (ele?.cong !== votingData3[index]?.cong) {
          setDynamicClasses3((current) => [...current, `cong ${index}`]);
          setTimeout(() => {
            setDynamicClasses3([]);
          }, 5000);
        }
        if (ele?.other !== votingData3[index]?.other) {
          setDynamicClasses3((current) => [...current, `other ${index}`]);
          setTimeout(() => {
            setDynamicClasses3([]);
          }, 5000);
        }
      }
    });
  }, [zone3]);

  const WAIT_TIME = 5000;

  useEffect(() => {
    const id = setInterval(() => {
      fetchLocation();
    }, WAIT_TIME);
    return () => clearInterval(id);
  }, [len1, len, len2, len3]);

  useEffect(() => {
    fetchLocation();
  }, [extrarow]);

  const donut = [
    {
      name: "BJP",
      color: "orange",
      value: parseInt(voting?.gj?.bjp),
    },
    {
      name: "CONG",
      color: "red",
      value: parseInt(voting?.gj?.cong),
    },
    {
      name: "AAP",
      color: "#009ADA",
      value: parseInt(voting?.gj?.aap),
    },
    {
      name: "OTHER",
      color: "grey",
      value: parseInt(voting?.gj?.other),
    },
  ];

  const donut1 = [
    {
      name: "BJP",
      color: "orange",
      value: parseInt(voting?.hp?.bjp),
    },
    {
      name: "CONG",
      color: "red",
      value: parseInt(voting?.hp?.cong),
    },
    {
      name: "AAP",
      color: "#009ADA",
      value: parseInt(voting?.hp?.aap),
    },
    {
      name: "OTHER",
      color: "grey",
      value: parseInt(voting?.hp?.other),
    },
  ];

  return (
    <div className="row m-0 app">
      <div className="col-md-6 offset-md-3 col-lg-3 offset-lg-0 col-xl-3 offset-xl-0 p-0">
        {voting?.gj?.total_seat ? (
          <>
            <div className="col-xl-12 polltable p-0">
              <div className=" heading-main">
                <div className="party-name2">
                  <div className="nameofparty1">
                    <p className="party-1">ગુજરાત ચૂંટણી</p>
                  </div>
                  <div className="nameofparty2">
                    <p className="party-2">હિમાચલ પ્રદેશ ચૂંટણી</p>
                  </div>
                </div>
                <img className="ImageSwastik" src={swastik} alt=""></img>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-lg-6 col-xl-6 col-md-6 col-6 p-0">
                <DonutChart
                  data={donut}
                  total_seat={voting?.gj?.total_seat}
                  total_result={voting?.gj?.total_result}
                />
              </div>
              <div className="col-lg-6 col-xl-6 col-md-6 col-6 p-0">
                <DonutChart
                  data={donut1}
                  total_seat={voting?.hp?.total_seat}
                  total_result={voting?.hp?.total_result}
                />
              </div>
              <div className="col-12 p-0">
                <div className="hr-main-div">
                  <hr className="hr-after-donut" />
                  <span>2022</span>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-md-6 col-6  polltable">
                <div className=" main-voting-poll">
                  <div className="heading-vote">
                    <span className=" ">{voting?.zone1?.name}</span>
                    <div className="votes">
                      {voting?.zone1?.total_result}/{voting?.zone1?.total_seat}
                    </div>
                  </div>
                  <table class="Voting-table ">
                    <thead>
                      <tr>
                        <th scope="col" className="district">
                          <span>જીલ્લો</span>
                        </th>
                        <th scope="col" className="bjp">
                          {" "}
                          <span>BJP</span>{" "}
                        </th>
                        <th scope="col" className="cong">
                          {" "}
                          <span>CONG</span>{" "}
                        </th>
                        <th scope="col" className="aap">
                          {" "}
                          <span>AAP</span>
                        </th>
                        <th scope="col" className="other">
                          <span>OTHER</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {votingData?.map((ele, index) => (
                        <>
                          {index >= my_time && index < _mytime && (
                            <tr>
                              <>
                                <th scope="row" className="district-name">
                                  {ele.dist ? ele.dist : ""}
                                </th>

                                <td
                                  className={` district ${
                                    dynamicClasses.includes(`bjp ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  <span>{ele.bjp}</span>
                                </td>
                                <td
                                  className={` bjp ${
                                    dynamicClasses.includes(`cong ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.cong ? ele.cong : ""}
                                </td>
                                <td
                                  className={` cong ${
                                    dynamicClasses.includes(`aap ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.aap ? ele.aap : ""}
                                </td>
                                <td
                                  className={` other ${
                                    dynamicClasses.includes(`other ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.other ? ele.other : ""}
                                </td>
                              </>
                            </tr>
                          )}

                          {index >= 0 && !my_time && index < 6 && !_mytime && (
                            <tr>
                              <>
                                <th scope="row" className="district-name">
                                  {ele.dist ? ele.dist : ""}
                                </th>

                                <td
                                  className={` district ${
                                    dynamicClasses.includes(`bjp ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  <span>{ele.bjp}</span>
                                </td>
                                <td
                                  className={` bjp ${
                                    dynamicClasses.includes(`cong ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.cong ? ele.cong : ""}
                                </td>
                                <td
                                  className={` cong ${
                                    dynamicClasses.includes(`aap ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.aap ? ele.aap : ""}
                                </td>
                                <td
                                  className={` other ${
                                    dynamicClasses.includes(`other ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.other ? ele.other : ""}
                                </td>
                              </>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className=" main-voting-poll mt-3">
                  <div className="heading-vote">
                    <span className=" ">{voting?.zone3?.name}</span>
                    <div className="votes">
                      {voting?.zone3?.total_result}/{voting?.zone3?.total_seat}
                    </div>
                  </div>
                  <table class="Voting-table ">
                    <thead>
                      <tr>
                        <th scope="col" className="district">
                          <span>જીલ્લો</span>
                        </th>
                        <th scope="col" className="bjp">
                          {" "}
                          BJP{" "}
                        </th>
                        <th scope="col" className="cong">
                          {" "}
                          CONG
                        </th>
                        <th scope="col" className="aap">
                          {" "}
                          AAP
                        </th>
                        <th scope="col" className="other">
                          OTHER
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {votingData2?.map((ele, index) => (
                        <>
                          {index >= my_time2 && index < _mytime2 && (
                            <tr>
                              <>
                                <th scope="row" className="district-name">
                                  {ele.dist ? ele.dist : ""}
                                </th>

                                <td
                                  className={` district ${
                                    dynamicClasses2.includes(`bjp ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  <span>{ele.bjp}</span>
                                </td>
                                <td
                                  className={` bjp ${
                                    dynamicClasses2.includes(`cong ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.cong ? ele.cong : ""}
                                </td>
                                <td
                                  className={` cong ${
                                    dynamicClasses2.includes(`aap ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.aap ? ele.aap : ""}
                                </td>
                                <td
                                  className={` other ${
                                    dynamicClasses2.includes(`other ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.other ? ele.other : ""}
                                </td>
                              </>
                            </tr>
                          )}

                          {index >= 0 && !my_time2 && index < 6 && !_mytime2 && (
                            <tr>
                              <>
                                <th scope="row" className="district-name">
                                  {ele.dist ? ele.dist : ""}
                                </th>

                                <td
                                  className={` district ${
                                    dynamicClasses2.includes(`bjp ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  <span>{ele.bjp}</span>
                                </td>
                                <td
                                  className={` bjp ${
                                    dynamicClasses2.includes(`cong ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.cong ? ele.cong : ""}
                                </td>
                                <td
                                  className={` cong ${
                                    dynamicClasses2.includes(`aap ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.aap ? ele.aap : ""}
                                </td>
                                <td
                                  className={` other ${
                                    dynamicClasses2.includes(`other ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.other ? ele.other : ""}
                                </td>
                              </>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-6  col-xl-6 col-md-6 col-6 polltable">
                <div className="main-voting-poll">
                  <div className="heading-vote">
                    <span className=" ">{voting?.zone4?.name}</span>
                    <div className="votes">
                      {voting?.zone4?.total_result}/{voting?.zone4?.total_seat}
                    </div>
                  </div>
                  <table class="Voting-table ">
                    <thead>
                      <tr>
                        <th scope="col" className="district">
                          <span>જીલ્લો</span>
                        </th>
                        <th scope="col" className="bjp">
                          {" "}
                          BJP{" "}
                        </th>
                        <th scope="col" className="cong">
                          {" "}
                          CONG
                        </th>
                        <th scope="col" className="aap">
                          {" "}
                          AAP
                        </th>
                        <th scope="col" className="other">
                          OTHER
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {votingData3?.map((ele, index) => (
                        <>
                          {index >= my_time3 && index < _mytime3 && (
                            <tr>
                              <>
                                <th scope="row" className="district-name">
                                  {ele.dist ? ele.dist : ""}
                                </th>

                                <td
                                  className={` district ${
                                    dynamicClasses3.includes(`bjp ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  <span>{ele.bjp}</span>
                                </td>
                                <td
                                  className={` bjp ${
                                    dynamicClasses3.includes(`cong ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.cong ? ele.cong : ""}
                                </td>
                                <td
                                  className={` cong ${
                                    dynamicClasses3.includes(`aap ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.aap ? ele.aap : ""}
                                </td>
                                <td
                                  className={` other ${
                                    dynamicClasses3.includes(`other ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.other ? ele.other : ""}
                                </td>
                              </>
                            </tr>
                          )}

                          {index >= 0 && !my_time3 && index < 6 && !_mytime3 && (
                            <tr>
                              <>
                                <th scope="row" className="district-name">
                                  {ele.dist ? ele.dist : ""}
                                </th>

                                <td
                                  className={` district ${
                                    dynamicClasses3.includes(`bjp ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  <span>{ele.bjp}</span>
                                </td>
                                <td
                                  className={` bjp ${
                                    dynamicClasses3.includes(`cong ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.cong ? ele.cong : ""}
                                </td>
                                <td
                                  className={` cong ${
                                    dynamicClasses3.includes(`aap ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.aap ? ele.aap : ""}
                                </td>
                                <td
                                  className={` other ${
                                    dynamicClasses3.includes(`other ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.other ? ele.other : ""}
                                </td>
                              </>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mt-3 main-voting-poll">
                  <div className="heading-vote">
                    <span className=" ">{voting?.zone2?.name}</span>
                    <div className="votes">
                      {voting?.zone2?.total_result}/{voting?.zone2?.total_seat}
                    </div>
                  </div>
                  <table class="Voting-table ">
                    <thead>
                      <tr>
                        <th scope="col" className="district">
                          <span>જીલ્લો</span>
                        </th>
                        <th scope="col" className="bjp">
                          {" "}
                          BJP{" "}
                        </th>
                        <th scope="col" className="cong">
                          {" "}
                          CONG
                        </th>
                        <th scope="col" className="aap">
                          {" "}
                          AAP
                        </th>
                        <th scope="col" className="other">
                          OTHER
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {votingData1?.map((ele, index) => (
                        <>
                          {index >= my_time1 && index < _mytime1 && (
                            <tr>
                              <>
                                <th scope="row" className="district-name">
                                  {ele.dist ? ele.dist : ""}
                                </th>

                                <td
                                  className={` district ${
                                    dynamicClasses1.includes(`bjp ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  <span>{ele.bjp}</span>
                                </td>
                                <td
                                  className={` bjp ${
                                    dynamicClasses1.includes(`cong ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.cong ? ele.cong : ""}
                                </td>
                                <td
                                  className={` cong ${
                                    dynamicClasses1.includes(`aap ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.aap ? ele.aap : ""}
                                </td>
                                <td
                                  className={` other ${
                                    dynamicClasses1.includes(`other ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.other ? ele.other : ""}
                                </td>
                              </>
                            </tr>
                          )}

                          {index >= 0 && !my_time1 && index < 6 && !_mytime1 && (
                            <tr>
                              <>
                                <th scope="row" className="district-name">
                                  {ele.dist ? ele.dist : ""}
                                </th>

                                <td
                                  className={` district ${
                                    dynamicClasses1.includes(`bjp ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  <span>{ele.bjp}</span>
                                </td>
                                <td
                                  className={` bjp ${
                                    dynamicClasses1.includes(`cong ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.cong ? ele.cong : ""}
                                </td>
                                <td
                                  className={` cong ${
                                    dynamicClasses1.includes(`aap ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.aap ? ele.aap : ""}
                                </td>
                                <td
                                  className={` other ${
                                    dynamicClasses1.includes(`other ${index}`)
                                      ? "addflag"
                                      : ""
                                  } `}
                                >
                                  {ele.other ? ele.other : ""}
                                </td>
                              </>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CommonElection;
