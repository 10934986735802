const DonutChart = ({ data, total_seat, total_result }) => {
  const cx = 50;
  const cy = 50;
  const strokeWidth = 15;
  const radius = 40;
  const dashArray = 2 * Math.PI * radius;
  const startAngle = -90;

  let filled = 0;

  const sum = data.reduce((sum, item) => {
    return sum + item.value;
  }, 0);

  const ratio = 100 / sum;

  data?.map((obj) => {
    const itemRatio = ratio * obj.value;

    obj.itemRatio = itemRatio;
    obj.angle = (filled * 360) / 100 + startAngle;
    obj.offset = dashArray - (dashArray * itemRatio) / 100;
    obj.filled = filled;

    filled += itemRatio;
  });

  return (
    <>
      <div className="donutchart-compo">
        <div className="chart">
          <div className="donut-count">
            <div className="donut-chart">
              <svg
                // style={{ width: "70px" }}
                height="90px"
                viewBox="0 0 100 100"
              >
                {data?.map((item, index) => (
                  <circle
                    key={index}
                    cx={cx}
                    cy={cy}
                    r={radius}
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    stroke={item.color}
                    strokeDasharray={dashArray}
                    strokeDashoffset={item.offset}
                    transform={`rotate(${item.angle} ${cx} ${cy})`}
                  >
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      from={`${startAngle} ${cx} ${cy}`}
                      to={`${item.angle} ${cx} ${cy}`}
                      dur="1s"
                    />

                    <title>
                      {item.name}: {item.value}
                    </title>
                  </circle>
                ))}
              </svg>
            </div>
            <div className="counter">
              <p className="total">{total_result}</p>
              <p className="outof">{total_seat}</p>
            </div>
          </div>
        </div>
        <div className="chart">
          <div className="items1">
            <ul className="items-names1 p-0">
              {data?.map((item, index) => (
                <>
                  {index > -1 && index < 2 ? (
                    <>
                      <div className="count">
                        <li
                          key={index++}
                          className="item-name"
                          style={{
                            background: ` ${item.color}`,
                            color: "white",
                            fontweight: "400",
                            padding: "3px",
                          }}
                        >
                          {item.name}
                        </li>
                        <li key={index++} className="item-name">
                          {item.value}
                        </li>
                      </div>
                    </>
                  ) : (
                    " "
                  )}
                </>
              ))}
            </ul>
          </div>
          <div className="items2">
            <ul className="items-names p-0">
              {data?.map((item, index) => (
                <>
                  {index > 1 && index < 5 ? (
                    <>
                      <div className="count">
                        <li
                          key={index++}
                          className="item-name"
                          style={{
                            background: ` ${item.color}`,
                            color: "white",
                            fontweight: "400",
                            padding: "3px",
                          }}
                        >
                          {item.name}
                        </li>
                        <li key={index++} className="item-name">
                          {item.value}
                        </li>
                      </div>
                    </>
                  ) : (
                    " "
                  )}
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonutChart;
